.auth-container {
  display: flex;
  justify-content: start;
  height: calc(100vh - 64px) !important;
  gap: 4rem;
}

.auth .app-header {
  border-bottom: 1px solid #f2f2f0;
}

.auth-box {
  position: relative;
  text-align: center;
}

.auth-image {
  max-width: 550px;
  max-height: 693px;
  width: 100%;
}

.auth-box img {
  align-self: center;
  max-width: 150px;
  object-fit: cover;
}

.auth-form {
  max-width: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.auth-form .undefined .flex-row-wrap {
  justify-content: start !important;
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo_Container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.auth-social-button {
  margin-top: 20px;
  margin-bottom: 40px;
}

.auth-register-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
.auth-register-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.auth-register-list-item {
  gap: 0.5rem;
}

.auth-form .text-field-container {
  margin-bottom: 0.5rem;
}

.auth-form .InputContainer {
  margin-top: 0;
}

.auth-form .InputContainer label {
  margin-bottom: 4px;
}

@media (max-width: 950px) {
  .auth-container {
    flex-direction: column;
    gap: 2rem;
    padding-top: 1rem;
    height: auto !important;
    align-items: center;
  }

  .auth-container > div {
    margin: 0 5px;
  }

  .auth-form {
    margin: 30px 0;
  }

  .auth-text {
    margin-top: 20px;
  }

  .image-container {
    width: 100%;
    align-items: center;
  }

  .auth-register-list {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
}
