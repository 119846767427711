.display-large {
  font-family: 'Sofia Pro Soft Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.04em;
}

.display-medium-large {
  font-family: 'Sofia Pro Soft Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -0.04em;
}

.display-medium {
  font-family: 'Sofia Pro Soft Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -0.04em;
}
.display-small {
  font-family: 'Sofia Pro Soft Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 64px;
  letter-spacing: -0.04em;
}
.heading-large {
  font-family: 'Sofia Pro Soft Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.02em;
}

.heading-medium {
  font-family: 'Sofia Pro Soft Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
}

.heading-small {
  font-family: 'Sofia Pro Soft Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.02em;
}

.title-large {
  font-family: 'Sofia Pro Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.02em;
}

.title-medium {
  font-family: 'Sofia Pro Medium';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.title-small {
  font-family: 'Sofia Pro Medium' !important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 18px!important;
  line-height: 30px!important;
  letter-spacing: -0.02em!important;
}

.text-large {
  font-family: 'Sofia Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

.text-standard {
  font-family: 'Sofia Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
}

.text-small {
  font-family: 'Sofia Pro' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.text-extra-small {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 20px !important;
}
.bold-font {
  font-weight: 700 !important;
}
.underline-font {
  text-decoration: underline;
}
.icon-button {
  margin-top: 10px;
}
.green-font {
  color: var(--green-color);
}

.emerald-font {
  color: var(--emerald-color);
}
.orange-font {
  color: var(--orange-color);
}
.white-font {
  color: var(--bg1)!important;
}
.black-font {
  color: var(--text-main) !important;
}
.red-font {
  color: var(--red-color);
}
.grey-font {
  color: var(--text-secondary) !important;
}
.italic-font {
  font-style: italic !important;
}
.bg-bg1 {
  background-color: var(--bg1);
}
.bg-bg2 {
  background-color: var(--bg2);
}
.bg-emerald {
  background-color: var(--emerald-color);
}
.bg-mint {
  background-color: var(--mint-color);
}
.bg-blue-half {
  background-color: var(--blue-color-half);
}
.bg-blue {
  background-color: var(--blue-color);
}
.bg-earth {
  background-color: var(--earth-color-half);
}
.bg-dark-grey{
  background-color: var(--dark-grey);
}
@media screen and (max-width: 575px) {
  .display-large {
    font-family: 'Sofia Pro Soft';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }

  .display-medium {
    font-family: 'Sofia Pro Soft';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: -0.02em;
  }
  .display-small {
    font-family: 'Sofia Pro Soft Medium';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.02em;
  }
  .heading-large {
    font-family: 'Sofia Pro Soft';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.02em;
  }
  .heading-medium {
    font-family: 'Sofia Pro Soft';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
  }

  .title-large {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
  }

  .title-medium {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
  }

  .text-large {
    font-family: 'Sofia Pro' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 26px !important;
  }

  .text-standard {
    font-family: 'Sofia Pro' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .text-small {
    font-family: 'Sofia Pro' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 18px !important;
  }
}
