.container {
  padding: 0 10vw;
}

.address_lookup {
  background: url('../../../images/Illustrations/thermly-cat-on-desk.svg') #c0e1eb no-repeat 100%
    100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 32px;
  background-size: 500px;
  margin-top: 10px;
}

.address_lookup_contents {
  padding: 64px;
  max-width: 1200px;
}

.addressFormContainer {
  max-width: 900px;
}

.addressFormContainer .InputContainer label {
  margin-bottom: 4px;
}

.addressFormContainer form {
  max-width: 400px;
}

@media (max-width: 1400px) {
  .address_lookup {
    background-size: 400px;
  }
}

@media (max-width: 800px) {
  .address_lookup_contents {
    margin-bottom: 200px;
  }
}

@media (max-width: 575px) {
  .address_lookup {
    background-size: 300px;
  }

  .address_lookup_contents {
    padding: 1.5rem;
  }

  .address_lookup .btn {
    width: 100%;
    padding: 14px 20px;
    justify-content: space-between;
  }

  .address_lookup .btn-label {
    justify-content: space-between;
  }
}

.gridColumn {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.addressFormContainer {
  width: 100%;
}

.addressFormContainer .InputContainer {
  margin-top: 0;
}

.addressFormContainer .text-field-container {
  margin-bottom: 0;
}

.addressFormContainer .checkbox-label {
  margin-left: 1rem;
}

.addressFormContainer .checkbox-custom {
  height: 24px;
  width: 24px;
  border: 1px solid var(--emerald-color) !important;
  background-color: transparent;
}

.address_list {
  background-color: white;
  border-radius: 16px;
  padding: var(--padding-medium);
}

.address_list_item {
  display: flex;
  align-items: center;
}

.address_list_title {
  margin-left: 20px;
  font-weight: 700;
}

.address_footer {
  display: flex;
  align-content: center;
  margin: 11px 0 11px 10px;
}

.address_footer a {
  margin-left: 10px;
}

.list_description {
  margin-left: 44px;
  margin-bottom: 10px;
  width: fit-content;
}

.checkCricleIcon {
  color: var(--orange-color);
}

.hidden {
  display: none;
}

.address_type {
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 10px !important;
  cursor: 'pointer';
}

.image_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.right_image {
  width: 60%;
  max-width: 250px;
}

.process-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  gap: 2rem;
}

.step-container {
  flex-grow: 1;
  justify-content: space-between !important;
}

.trial_location {
  background: url('../../../images/Illustrations/thermly_cat_and_heater.svg') var(--blue-color)
    no-repeat top right;
  border-radius: 20px;
  background-size: 150px;
  margin-bottom: 100px;
}

.trial_location_contents {
  padding-top: 100px;
  padding-bottom: 100px;
}

.trial-location-img {
  max-width: 80%;
  height: auto;
}

.process {
  margin-bottom: 100px;
}

.process-img {
  width: 100%;
}

.what-box {
  background-color: var(--bg2);
  border-radius: 16px;
  padding: var(--padding-medium);
}

@media (max-width: 991px) {
  .readinessDivider {
    width: 100%;
    margin: 20px 0;
    height: 1px;
    background: var(--bg5);
  }

  .container {
    display: block;
    padding: var(--padding-small);
  }

  .right-section {
    margin-left: 0;
  }
}

@media screen and (max-width: 575px) {
  .address_container {
    padding-right: 0;
  }
}

.geo-modal {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  box-shadow: 24;
  padding: 3rem 2rem;
  border-radius: 8px;
}

.modal-close-button {
  width: 20px;
  height: 20px;
  position: fixed !important;
  right: 20px;
  top: 20px;
}
